











import { defineComponent, onMounted } from '@vue/composition-api';
import { useVisitor } from '~/composables';
import criteoHelper from '~/helpers/criteo/criteo';
import { useContext } from '@nuxtjs/composition-api';
import { CriteoIdByMarket } from '~/helpers/criteo/getIdByMarket';

export default defineComponent({
  name: 'HomepageTracking',
  props: {
    isAuthenticated: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
    },
  },
  setup(props) {
    const isClient = typeof window !== 'undefined'; // Ensure this runs only on the client side
    const { app } = useContext();

    onMounted(async () => {
      const { visitorId } = useVisitor();
      const criteoId = CriteoIdByMarket[app.$cookies.get('vsf-market')];
      const criteo = criteoHelper({
        criteoId: criteoId,
        app,
        user: props.user,
        isAuthenticated: props.isAuthenticated,
        visitorId,
      });
      const deviceType = criteo.getDeviceType();
      const data: any = {
        id: criteoId,
        visitorId: visitorId.value, // Optional: visitor ID, if available
        deviceType: deviceType,
      };

      if (props.isAuthenticated) {
        const email = criteo.encryptValue(props.user.email);
        data.email = email;
        data.hashMethod = 'MD5';
        data.customerId = email;
      }

      if (criteoId !== undefined) {
        app.$criteo.homepageTag(data);
      }
    });

    return {
      isClient,
      app,
    };
  },
});
