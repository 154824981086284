import CryptoJS from 'crypto-js';

const criteoHelper = ({ criteoId, app, user, isAuthenticated, visitorId }) => {
  const getDeviceType = () => {
    return /iPad/.test(navigator.userAgent) ? 't' : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? 'm' : 'd';
  };

  const encryptValue = (text) => {
    return CryptoJS.MD5(text).toString(CryptoJS.enc.Hex);
  };

  const getCurrentCurrency = () => {
    return app.$cookies.get('vsf-currency') || 'EUR';
  };

  const addToCartPush = (id, price, quantity) => {
    // check if id is a string
    if (typeof id !== 'string') {
      throw new TypeError('Product id must be a string');
    }

    // check if price is a number
    if (typeof price !== 'number') {
      throw new TypeError('Product price must be a number');
    }

    // check if quantity is a number
    if (typeof quantity !== 'number') {
      throw new TypeError('Product quantity must be a number');
    }

    const visitorIdValue = visitorId.value;

    const data = {
      id: criteoId,
      item: {
        id,
        price,
        quantity,
      },
      currency: getCurrentCurrency(),
      visitorId: visitorIdValue,
      deviceType: getDeviceType(),
    };

    if (isAuthenticated) {
      const email = encryptValue(user.email);
      data.email = email;
      data.hashMethod = 'sha256';
      data.customerId = email;
    }

    app.$criteo.addToCartTag(data);
  };

  const salesTagPush = (orderId, items, transactionValue) => {
    const visitorIdValue = visitorId.value;
    const data = {
      id: criteoId,
      orderId: orderId,
      currency: getCurrentCurrency(),
      cartItems: items,
      transactionValue,
      visitorId: visitorIdValue,
      deviceType: getDeviceType(),
    };

    if (isAuthenticated) {
      const email = encryptValue(user.email);
      data.email = email;
      data.hashMethod = 'sha256';
      data.customerId = email;
    }

    app.$criteo.salesTag(data);
  };

  return {
    getDeviceType,
    addToCartPush,
    encryptValue,
    salesTagPush,
    getCurrentCurrency,
  };
};

export default criteoHelper;
